

















































































import { defineComponent, onBeforeMount, ref, watch } from '@vue/composition-api'
import Cookies from 'js-cookie'
import { useState } from '@u3u/vue-hooks'

import { LOGIN_TOKEN } from '@/inc/app.config'

import BrokerLogin from '@/components/forms/BrokerLogin.vue'
import VInput from '@/components/form/VInput.vue'
import FeedbackMessage from '@/components/ui/FeedbackMessage.vue'
import { enableDashboard } from '@/composables/ui'

export default defineComponent({
  name: 'home',
  components: { FeedbackMessage, VInput, BrokerLogin },

  setup(_props, ctx) {
    const err = ref<Record<string, unknown>>({})
    const isLoading = ref(false)
    const isExpired = ref(false)
    const state = useState(['resource'])
    const { $isMobile } = ctx.root
    const { hasUser } = useState('user', {
      hasUser: 'hasUser',
      isFetching: 'isLoading',
    })

    const onAuthBtnClick = () => {
      if (!ctx.root.$route.query.token?.length) {
        enableDashboard()
      }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const connectionData = ref<Record<string, any>>({
      // eslint-disable-next-line camelcase
      connection_token: Cookies.get(LOGIN_TOKEN),
    })

    const connectionToken = (type?: string) => {
      if (!ctx.root.$route.query.token?.length) {
        return `{"confirmationCode": ${type}}`
      }

      return JSON.stringify(connectionData.value)
    }

    const isBroker = ref(false)
    const updateConnectionState = data => {
      connectionData.value = {
        // eslint-disable-next-line camelcase
        connection_token: connectionData.value.connection_token,
        ...data,
      }
    }
    const errorCheck = () => {
      switch (ctx.root.$route.query.error) {
        case 'login-data':
          err.value = {
            code: 500,
            description: ctx.root.$t('landing.errors.loginData'),
          }
          break

        case 'login-error':
          err.value = {
            code: 401,
            description: ctx.root.$t('landing.errors.loginError'),
          }
          break

        default:
          break
      }
    }

    onBeforeMount(() => {
      isExpired.value = Boolean(Number(ctx.root.$route.query.expired))
      isBroker.value = Boolean(ctx.root.$route.query.crt)
      delete ctx.root.$route.query.expired
      errorCheck()
    })

    watch(hasUser, value => {
      if (value) {
        isLoading.value = false
        isExpired.value = false
        delete ctx.root.$route.query.expired
      }
    })

    return {
      onAuthBtnClick,
      isBroker,
      updateConnectionState,
      connectionToken,
      isExpired,
      $isMobile,
      err,
      isLoading,
      hasUser,
      content: state.resource.value?.content,
    }
  },
})
